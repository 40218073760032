<template>
  <v-dialog
    max-width="1280px"
    width="100%"
    :value="true"
    scrollable
    persistent
    @keydown.esc="close"
  >
    <v-card>
      <v-progress-linear
        indeterminate
        color="error"
        v-if="loading"
      ></v-progress-linear>
      <v-card-title class="pa-0">
        <v-toolbar :color="titleColor" dark class="mb-2">
          <v-btn icon>
            <v-icon>mdi-account-minus</v-icon>
          </v-btn>
          <v-toolbar-title v-if="absence">
            {{ titleText }}
          </v-toolbar-title>
          <v-spacer />
          <v-btn @click="close" icon>
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
      </v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="4">
            <v-card>
              <v-list-item>
                <PersonInput
                  clearable
                  @input="updateCourses"
                  group="teacher"
                  label="Lehrer*in"
                  v-model="absence.teacher"
                />
              </v-list-item>
              <v-list-item>
                <DateInput
                  @input="updateCourses"
                  label="von"
                  v-model="absence.startDate"
                />
              </v-list-item>
              <v-list-item>
                <DateInput
                  @input="updateCourses"
                  label="bis"
                  v-model="absence.endDate"
                />
              </v-list-item>
              <v-list-item>
                <LookupValueInput
                  label="Grund"
                  :items="absenceCauses"
                  v-model="absence.cause"
                />
              </v-list-item>
              <v-list-item>
                <v-text-field label="Kommentar" v-model="absence.comment" />
              </v-list-item>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card>
              <v-data-table
                :headers="headers"
                :items="courses"
                :items-per-page="15"
                :sort-by="'code'"
              >
                <template v-slot:item.term="{ item }">
                  {{ item.term.shortText }}
                </template>
                <template v-slot:item.teachers="{ item }">
                  {{ item.teachers.map((el) => el.code).join(", ") }} </template
                >$
                <template v-slot:item.startDate="{ item }">
                  <div
                    v-for="(substitution, index) in item.substitutions"
                    :key="'startDate' + index"
                    class="my-1"
                  >
                    <DatePicker v-model="substitution.startDate" />
                  </div>
                </template>
                <template v-slot:item.substitute="{ item }">
                  <div
                    v-for="(substitution, index) in item.substitutions"
                    :key="'substitute' + index"
                    class="my-1"
                  >
                    <PersonPicker
                      v-model="substitution.substitute"
                      group="teacher"
                    />
                  </div>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-btn
                    v-if="
                      item.substitutions[0].substitute &&
                      item.substitutions[0].substitute.id
                    "
                    icon
                    @click="addSubstitute(item.id)"
                    ><v-icon>mdi-plus</v-icon></v-btn
                  >
                </template>
              </v-data-table>
            </v-card>
          </v-col>
        </v-row>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-btn v-if="!create" text @click="del" color="error"
          ><v-icon left>mdi-delete</v-icon> Löschen</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn text @click="save" color="success"
          ><v-icon left>mdi-content-save</v-icon> Speichern</v-btn
        >
        <v-btn text @click="close"
          ><v-icon left>mdi-close</v-icon> Abbrechen</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import DateInput from "common/components/DateInput.vue";
import DatePicker from "@/components/DatePicker.vue";
import LookupValueInput from "common/components/LookupValueInput.vue";
import PersonInput from "common/components/PersonInput.vue";
import PersonPicker from "@/components/PersonPicker";
export default {
  name: "AbsencesDetails",
  components: {
    DateInput,
    DatePicker,
    LookupValueInput,
    PersonInput,
    PersonPicker,
  },
  props: ["id"],
  data() {
    return {
      create: false,
      absence: {
        absenceCause: null,
        startDate: null,
        endDate: null,
        teacher: null,
        courses: [],
        map: [],
      },
      absenceCauses: [],
      courses: [],
      headers: [
        { text: "Kurs", value: "code" },
        { text: "LK", value: "teachers" },
        { text: "Sem.", value: "term" },
        { text: "Stellvertretung", value: "substitute" },
        { text: "aktiv ab", value: "startDate" },
        { text: "", value: "actions" },
      ],
      loading: false,
    };
  },
  computed: {
    titleColor() {
      return this.create ? "success" : "error";
    },
    titleText() {
      return this.create ? "Abwesenheit erstellen" : "Abwesenheit ändern";
    },
  },
  methods: {
    addSubstitute(id) {
      this.courses
        .find((course) => course.id === id)
        .substitutions.push({ substitute: {} });
    },
    close() {
      this.$router.back();
    },
    async del() {
      if (
        await this.$root.confirm({
          message: "Soll diese Abwesenheit wirklich gelöscht werden?",
          color: "error",
          icon: "mdi-trash-can",
        })
      ) {
        await this.apiDelete({
          resource: "teacher/absence",
          id: this.absence.id,
        });
        this.$emit("dataChanged");
        this.$router.back();
      }
    },
    async save() {
      const substitutions = [];
      this.courses.forEach((course) => {
        course.substitutions.forEach((substitution) => {
          if (substitution.substitute) {
            substitutions.push({
              course: course,
              substitute: substitution.substitute,
              startDate: substitution.startDate,
            });
          }
        });
      });
      this.absence.substitutions = substitutions;
      if (this.absence.id) {
        await this.apiPut({ resource: "teacher/absence", data: this.absence });
      } else {
        await this.apiPost({ resource: "teacher/absence", data: this.absence });
      }
      this.$emit("dataChanged");
      this.$router.back();
    },
    async updateCourses() {
      if (
        !(
          this.absence.teacher &&
          this.absence.startDate &&
          this.absence.endDate
        )
      ) {
        this.courses = [];
        return;
      }
      const courses = await this.apiList({
        resource: "course/course",
        query: `person=${this.absence.teacher.id}&startDate=${this.absence.startDate}&endDate=${this.absence.endDate}`,
      });
      for (let i = 0; i < courses.length; ++i) {
        if (this.absence.map[courses[i].id]) {
          courses[i].substitutions = this.absence.map[courses[i].id];
        } else {
          courses[i].substitutions = [
            {
              substitute: {},
            },
          ];
        }
      }
      this.courses = courses;
    },
  },
  async created() {
    this.absenceCauses = await this.apiList({
      resource: "teacher/absencecause",
    });
    this.create = this.id === "new";
    if (!this.create) {
      this.loading = true;
      const absence = await this.apiGet({
        resource: "teacher/absence",
        id: this.id,
      });
      this.loading = false;
      absence.map = {};
      absence.substitutions.forEach((substitution) => {
        if (!absence.map[substitution.course]) {
          absence.map[substitution.course] = [substitution];
        } else {
          absence.map[substitution.course].push(substitution);
        }
      });
      this.absence = absence;
      this.updateCourses();
    }
  },
};
</script>
